export default {
  'Execution Fee': 'Execution Fee',
  'This rate of return cannot be achieved': 'Unable to reach this return rate.',
  'Open Fee': 'Open Fee',
  Indicators: 'Indicators',
  Result: 'Result',
  'Exit Price': 'Exit Price',
  Tradingview: 'Tradingview',
  'Initial Collateral': 'Initial Collateral',
  'No Open Positions': 'No Open Positions',
  'Confirm Close': 'Confirm',
  'Are you sure you want to close all positions? lt may require multiple wallet':
    'Are you sure you want to close all positions? lt may require multiple wallet.',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)':
    'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)',
  'BLEX Arbitrum Goerli Testnet': 'BLEX Arbitrum Goerli Testnet',
  'Keep Leverage {lev}x': 'Keep Leverage {lev}x',
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.",
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.':
    'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.',
  'View status': 'View Status',
  'Please select a network': 'Please select a network',
  Network: 'Network',
  'Receive test coins': 'Receive test coins',
  Language: 'Language',
  connected_to: 'Connected to {chainName}',
  Error: 'Error',
  'hide error': 'hide error',
  'show error': 'Show Error',
  'The system is currently busy': 'The system is currently busy',
  'Transaction sent.': 'Request Sent.',
  'Transaction completed!': 'Request sent successfully. Please be patient while waiting for execution.',
  'Close All': 'Close All',
  status: 'Status',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.',
  'BLEX {testnet} Testnet': 'BLEX {testnet} Testnet',
  'Website Pickup': 'Website Pickup',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.',
  'Edit Referral Code': 'Edit Referral Code',
  'Ends at': 'Ends at',
  'Starts in': 'Starts in',
  'Decentralized Perpetual Exchange': 'Decentralized Perpetual Exchange',
  'Please select a language': 'Please select a language',
  USDT: 'USDT',
  'Funding Fee / Day:': 'Funding Fee / Day:',
  'Using Friendly': 'Using Friendly',
  'Trading Smooth': 'Trading Smooth',
  copy: 'Link copied to clipboard.',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.':
    'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.',
  'BLE Vault APR': 'BLE Vault APR',
  'Coming Soon': 'Coming Soon',
  Telegram: 'Telegram',
  'in the last 24h': 'in the last 24h',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.':
    'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.',
  Referral: 'Referral',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!',
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.',
  Staking: 'Staking',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.',
  Trading: 'Trading',
  Connections: 'Connections',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!',
  'No Open Orders': 'No Open Orders',
  'BLEX T&C': 'BLEX T&C',
  'No History': 'No History',
  Hide: 'Hide',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.',
  'Receive Discounts': 'Receive Discounts',
  'Trading Discount': 'Trading Discount',
  'Bind Referral Code': 'Bind Referral Code',
  'Input a referral code to benefit from trade discount.': 'Input a referral code to benefit from trade discount.',
  'Get discounts on transaction fees when you make a trade.':
    'Get discounts on transaction fees when you make a trade.',
  'The discounts will be paid out in USDT every Wednesday.': 'The discounts will be paid out in USDT every Wednesday.',
  'Sorry, the page you visited does not exist': 'Sorry, the page you visited does not exist',
  Successful: 'Success',
  'Bridge ETH to Arbitrum': 'Bridge ETH to Arbitrum',
  'There is not enough ETH in your account on Arbitrum to send this transaction.':
    'You do not have enough eth in your account on arbitrum to send this transaction.',
  'Transaction failed due to RPC error.': 'Transaction failed due to RPC error.',
  'Please try changing the RPC url in your wallet settings.':
    'Please try changing the RPC url in your wallet settings.',
  'Transaction failed': 'Transaction failed',
  success: 'Success',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.':
    'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.',
  'Referral Code:': 'Referral Code:',
  'Index Perpetual Exchange': 'Index Perpetual Exchange',
  Request: 'Request {symbol}',
  'Drops are limited to1 request in 8 hours.': 'Drops are limited to 1 request in 8 hours.',
  FollowTwitter: 'Follow twitter and telegram for more {marketname}.',
  'Wallet Address:': 'Wallet Address:',
  'Total Balance (USDT)': 'Total Balance (USDT)',
  'Short Open Interest: {value}': 'Short Open Interest: {value}',
  'Long Open Interest: {value}': 'Long Open Interest: {value}',
  'Open Interest Balance': 'Open Interest Balance',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    'BLEX is a decentralized exchange allowing trading without the need for a username or password. The basis is a safe, fair, and effective index price-feeding model.',
  'How do BLEX Trade work?': 'How do BLEX trade work?',
  LONG: 'LONG',
  SHORT: 'SHORT',
  'Price Above Liq. Price': 'Price Above Liq. Price',
  'Price Below Liq. Price': 'Price Below Liq. Price',
  Slider: 'Slider',
  Customize: 'Customize',
  'Your wallet is not connected to': 'Your wallet is not connected to {chainName}',
  'Sell BLP': 'Sell BLP',
  'Buy BLP': 'Buy BLP',
  'Selling...': 'Selling...',
  'Buying...': 'Buying...',
  'Update Notice': 'Update notification',
  'Considers settled (closed) positions': 'Considers settled (closed) positions',
  'Fees are not factored into PnL': 'Fees are not factored into PNL',
  Volume: 'Volume',
  'Last Month': 'Last Month',
  'Last 2 Months': 'Last 2 Months',
  'Last 3 Months': 'Last 3 Months',
  Users: 'Users',
  'Users Actions': 'Users Actions',
  'Traders Profit vs. Loss': 'Traders Profit vs. Loss',
  'Traders Net PnL': 'Traders Net PNL',
  'Total Users': 'Total Users',
  'BLP Pool': 'BLP Pool',
  'Total Fees': 'Total Fees',
  Analytics: 'Analytics',
  '+$0': '+$0',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.",
  Status: 'Status',
  'What is BLEX':
    'BLEX aims to establish a decentralized perpetual exchange protocol. Its team members come from top-tier CEX, renowned hedge funds, and blockchain companies. The governance of BLEX will be determined by BLEX DAO. BLEX has offices in Singapore and Hong Kong, and DAO members contribute from all over the world through remote work. We look forward to welcoming you as a member of BLEX.',
  earnTip:
    'Note: both traders and lps have a risk of loss, make sure you familiarize yourself with the details before participating in BLEX.',
  'Media Kit': 'Media Kit',
  BugBounty: 'Bug Bounty',
  'I have understood': 'I have understood',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our':
    'We recognize that you are trying to access BLEX from a China IP address, please note that per our',
  ', we are unable to serve any users from an {regionName} IP.': ', we are unable to serve any users from an China IP.',
  'footer.ifYouHaveAnyQuestions':
    'If you have any questions about the functionality of our products, we would be happy to talk to you, please contact us.',
  'footer.products': 'Products',
  'Referral Terms': 'Referral Terms',
  'footer.reservedRights': '© 2023 BLEX. All rights reserved.',
  'Welcome to BLEX': 'Welcome to BLEX',
  'wallet.connect': 'Connect to sign in or create an account',
  'wallet.lastUpdated':
    'By connecting to the wallet, you agree to BLEX Terms & Conditions. (Last updated on 05/31/2023)',
  'Perpetual Exchange': 'Perpetual Exchange',
  'Perpetual Trading': 'Perpetual Trading',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.':
    'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.',
  'Earn Now': 'Earn Now',
  'View more': 'View more',
  'Explore The Endless': 'Explore the Endless',
  'Possibilities With BLEX': 'Possibilities with BLEX',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.':
    'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.',
  'Smooth Trading': 'Smooth Trading',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.',
  'Get Started': 'Get Started',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    'Both web and mobile app are available for trading with easy wallet integration. Joining takes just one simple step.',
  'Always at your side': 'Always at Your Side',
  'Join the BLEX community now!': 'Join the BLEX community now!',
  Mint: 'Mint',
  'We are continuously launching new perpetual contracts markets.':
    'We are continuously launching new perpetual contracts markets.',
  'Stake BLE and BLP to earn rewards.': 'Stake BLE and BLP to earn rewards.',
  'Get test tokens': 'Get test tokens',
  'Wallet Address': 'Wallet Address',
  'Share Revenue': 'Share Revenue',
  'Txn failed.': 'Txn failed.',
  ', we are unable to serve any users from an ': ', we are unable to serve any users from an',
  Switch: 'Switch',
  'Connect Wallet': 'Connect Wallet',
  'Drops are limited to 1 request in 8 hours.': 'Drops are limited to 1 request in 8 hours.',
  'Connect your wallet': 'Connect your wallet',
  Home: 'Home',
  'Bug bounty': 'Bug bounty',
  Test: 'Test',
  'Considers Settled (Closed) Positions': 'Considers Settled (Closed) Positions',
  'Fees Are Not Factored Into PnL': 'Fees Are Not Factored Into PNL',
  'The total number of positions opened and closed during the event':
    'The total number of positions opened and closed during the event',
  Days: 'Days',
  Hours: 'Hours',
  Minutes: 'Min',
  Seconds: 'Sec',
  PNL: 'PNL',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.':
    'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.',
  'Assets Under Management': 'Assets Under Management',
  Service: 'Service',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.':
    'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.',
  'Staking to Earn': 'Stake to Earn',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.',
  'Create referral code and start earning rebates!': 'Create referral code and start earning rebates!',
  'Invite User': 'Invite User',
  'Share your referral code on social media platforms such as Twitter and Facebook.':
    'Share your referral code on social media platforms such as Twitter and Facebook.',
  'Earn Rebates': 'Earn Rebates',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.',
  Referrers: 'Referrers',
  'Your Active Referral Code': 'Your Active Referral Code',
  'Please input a referral code to benefit from fee discounts.':
    'Please input a referral code to benefit from fee discounts.',
  'Referral code updated failed.': 'Failed to update referral code',
  'Adding referral code failed.': 'Failed to add referral code',
  'Submitting...': 'Submitting...',
  'Checking code...': 'Checking code...',
  Action: 'Action',
  'Creating...': 'Creating...',
  'Create code failed.': 'Failed to create referral code',
  'Create Referral Code': 'Create Referral Code',
  'Rebates earned by this account as a trader.': 'Rebates earned by this account as a trader.',
  'Volume traded by this account with an active referral code.':
    'Volume traded by this account with an active referral code.',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    'This represents the level of the referrer you have bound and the discount percentage you will receive on open/close fees, with the discount airdropped to your account every Wednesday.',
  'Total Claimable Reward': 'Total Claimable Reward',
  'Claim all': 'Claim all',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.':
    'Stake BLE and earn Escrowed BLEX rewards and 30% of platform fees distributed.',
  'Your Wallet': 'Your Wallet',
  'Your Staked': 'Your Staked',
  'Total Staked': 'Total Staked',
  'Buy BLE': 'Buy BLE',
  Stake: 'Stake',
  Unstake: 'Unstake',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.':
    'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.',
  'BLE Points': 'BLE Points',
  'The fuel for boosting esBLE to BLE.': 'The fuel for boosting esBLE to BLE.',
  'Buy BPT': 'Buy BPT',
  'Escrowed BLE': 'Escrowed BLE',
  'Convert esBLE to BLE through Boost and Vest.': 'Convert esBLE to BLE through Boost and Vest.',
  Boost: 'Boost',
  Vest: 'Vest',
  'Enjoy BLP Rewards': 'Enjoy BLP Rewards',
  'Total BLP amount': 'Total BLP amount',
  'Quantity of NFTs.': 'Quantity of NFTs.',
  Expand: 'Expand',
  'Mint successful': 'Mint successful',
  'Hurry up stake and enjoy the Rewards.': 'Hurry up stake and enjoy the Rewards.',
  Done: 'Done',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!':
    'With this NFT, you can enjoy the corresponding BLP award and double BPT income!',
  'Your Stake': 'Your Stake',
  'Stop loss': 'Stop loss',
  'Take profit': 'Take profit',
  'Creating Order...': 'Creating Order...',
  'Longing...': 'Longing...',
  'Shorting...': 'Shorting...',
  'Liq. Price': 'Liq. Price',
  'View Chart': 'View Chart',
  Export: 'Export',
  price: 'price',
  'Net Value': 'Net Value',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee':
    'Net Value: Initial Collateral + PNL - Funding Fee - Close Fee',
  Liq: 'Liq',
  'Price below liq. price': 'Price below liq. price',
  'Price above liq. price': 'Price above liq. price',
  'Slippage should be less than': 'Slippage should be less than',
  'Slippage should be more than': 'Slippage should be more than',
  'Creating order...': 'Creating order...',
  'Closing...': 'Closing...',
  'Index Price:': 'Index Price:',
  'Close:': 'Close:',
  'Available:': 'Available:',
  'Withdrawing...': 'Withdrawing...',
  'Depositing...': 'Depositing...',
  MAX: 'MAX',
  Amount: 'Amount',
  'Size:': 'Size:',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.':
    'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.',
  All: 'All',
  'Open Long': 'Open Long',
  'Open Short': 'Open Short',
  'Close Long': 'Close Long',
  'Close Short': 'Close Short',
  Liquidation: 'Liquidation',
  'Partial Liquidation': 'Partial Liquidation',
  'Partial liquidation': 'Partial liquidation',
  'Withdraw Collateral': 'Withdraw Collateral',
  'Deposit Collateral': 'Deposit Collateral',
  '5m': '5m',
  '15m': '15m',
  '1h': '1h',
  '4h': '4h',
  '1d': '1d',
  Executed: 'Executed',
  Canceled: 'Canceled',
  Original: 'Original',
  TradingView: 'TradingView',
  Currency: 'Currency',
  'Balance:': 'Balance:',
  'The BLP Vault is the counter party to all trades on BLEX.':
    'The BLP Vault is the counter party to all trades on BLEX.',
  'How do BLEX rewards work?': 'How do BLEX rewards work?',
  tradesNoHistory: 'No History',
  'How do BLEX Earn work?': 'How do BLEX Earn work?',
  'About Blex': 'About Blex',
  'Connect your wallet to continue': 'Connect Wallet',
  Filter: 'Filter',
  'No Record': 'No Record',
  'Go trade now!': 'Go Trade Now!',
  Cancle: 'Cancel',
  'Competitions 🔥': 'Competitions',
  Continue: 'Continue',
  Competitions: 'Competitions',
  Decentralized: 'Decentralized',
  'The index price retrieval is incorrect, please try again later.':
    'The index price retrieval is incorrect, please try again later.',
  'User friendly': 'User Friendly',
  'Event Details>>': 'Event Details>>',
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    'BLEX Arbitrum mainnet launch on Aug 6th! Reserve now to share the total $10,000 prize pool.',
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!',
  'Reservation Success': 'Reservation Success',
  Reserve: 'Reserve',
  'Countdown to the launch': 'Countdown to the launch',
  Connect: 'Connect',
  'Enter Referral code': 'Enter Referral Code',
  'Open Interest': 'Open Interest',
  AnalyticsDesc: 'Updated {time} at block {block}',
  '24h Change': '24h Change',
  '24h High': '24h High',
  '24h Low': '24h Low',
  'Buy / Sell BLP': 'Buy / Sell BLP',
  IMakeTrades: 'I have made {value} trades on BLEX, come with me!',
  'Index Price': 'Index Price',
  'Share Position': 'Share Position',
  'PnL After Fees:': 'PNL After Fees:',
  'Open Fee {value}': 'Open Fee: {value}',
  'Close Fee {value}': 'Close Fee: {value}',
  'Funding Fee {value}': 'Funding Fee: {value}',
  'PnL {value}': 'PNL: {value}',
  'Initial Collateral {value}': 'Initial Collateral: {value}',
  'Use the edit collateral icon to deposit or withdraw collateral.':
    'Use the edit collateral icon to deposit or withdraw collateral.',
  'Funding Fee:': 'Funding Fee:',
  'Collateral:  Initial Collateral - Funding Fee': 'Collateral:  Initial Collateral - Funding Fee',
  'Initial Collateral:': 'Initial Collateral:',
  ConfirmLong: 'Long {symbol}',
  ConfirmShort: 'Short {symbol}',
  Confirm: 'Confirm',
  Networks: 'Networks',
  ExecutionFee: 'Execution Fee: {fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    'The execution fee is only charged when the order is executed or canceled due to invalid execution.',
  OpenFeeLabel: 'Open Fee: {fee}',
  CloseFeeLabel: 'Close Fee: {fee}',
  FundingFee: 'Funding Fee: {fee}',
  Fees: 'Fees',
  Size: 'Size',
  Available: 'Available',
  MinResidualCollateralUSD: 'Min residual collateral: {USD} USD',
  LeftoverNetValue: 'Leftover net value below ${cleanFee}',
  LeftoverCollateral: 'Collateral below ${cleanFee}',
  AcceptMinimum: 'Accept minimum and {action}',
  ExistingIncrease: 'You have multiple existing increase {isLong} {toSymbol} limit orders',
  ExistingPosition: 'You already have a {isLong} position and this will increase the size of your existing position.',
  ConfirmOrderShort: 'Confirm {toSymbol} Short',
  ConfirmOrderLong: 'Confirm {toSymbol} Long',
  'Connected with': 'Connected with {value}',
  'View on Explorer': 'View on Explorer',
  'Txn failed': 'Txn failed',
  View: 'View',
  text: 'text',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange.',
  Homepage: 'Homepage',
  'Trading Volume': 'Trading Volume',
  Open: 'Open',
  Rank: 'Rank',
  Account: 'Account',
  '': '',
  'Cum.Size': 'Cum.Size',
  'Cum.Collateral': 'Cum.Collateral',
  Prize: 'Prize',
  Reward: 'Earn',
  'Total Profit': 'Total Profit',
  'The total volume accumulated between the activity': 'The total volume accumulated between the activity.',
  'BLP Supply': 'BLP Supply',
  'The total BLP supply accumulated between the activity': 'The total BLP supply accumulated between the activity',
  'Prize Pool': 'Prize Pool',
  'The estimated amount distirbuted to all top traders by competition end results':
    'The estimated amount distirbuted to all top traders by competition end results.',
  Trades: 'Trades',
  'Rank List': 'Rank',
  'Profit Rank': 'Profit Rank',
  Profit: 'Profit',
  'Trade ROI Rank': 'Trade ROI Rank',
  'Trade ROI': 'Trade ROI',
  'Trading Data': 'Trading Data',
  'Total volume': 'Total Volume',
  'Fees Paid': 'Fees Paid',
  'Trading Fees': 'Trading Fees',
  'Open/Close Fee': 'Open/Close Fee',
  'Funding Fee': 'Funding Fee',
  'Read More': 'Read More',
  'Average profit': 'Average profit',
  'Average loss': 'Average loss',
  'Address copied to your clipboard': 'Address copied to clipboard',
  Traders: 'Traders',
  'Enter referral code': 'Enter referral code',
  'Please input a referral code to benefit from fee discounts':
    'Please input a referral code to benefit from fee discounts',
  'Save Changes': 'Save Changes',
  'Referral code updated!': 'Referral code has been updated!',
  'Referral code updated failed': 'Failed to update referral code',
  'Adding referral code failed': 'Failed to add referral code',
  'Same as current active code': 'Same as current activity code',
  Submitting: 'Submitting',
  'Checking code': 'Checking code',
  'Referral code does not exist': 'Referral code does not exist',
  'Referral link copied to your clipboard': 'Referral link copied to clipboard.',
  Creating: 'Creating',
  'Enter a code': 'Enter a code',
  'Code already taken': 'Code already taken',
  Create: 'Create',
  title: 'title',
  desc: 'desc',
  'Total Trading Volume': 'Total Trading Volume',
  'Volume traded by this account with an active referral code':
    'Total volume traded by this account through active referral codes',
  'Total Traders Referred': 'Total Traders Referred',
  'Rebates earned by this account as a trader': 'Rebates earned by this account as a trader',
  'Total Rebates': 'Total Rebates',
  'Tier 1 (5% rebate)': 'Tier 1 (5% rebate)',
  'Edit Refferal code': 'Edit Refferal Code',
  'Active Referral Code': 'Active Referral Code',
  'Tier 1 (5% discount)': 'Tier 1 (5% discount)',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday',
  'Tier 1': 'Tier 1',
  Rewards: 'Earn',
  'Stake USDT and earn rewards from fees generated on the BLEX.':
    'Stake USDT and earn rewards from fees generated on BLEX.',
  'Learn More': 'Learn More',
  'Enter an amount': 'Enter an amount',
  'Insufficient USDT balance': 'Insufficient USDT balance',
  'Insufficient BLP balance': 'Insufficient BLP balance',
  Buy: 'Buy',
  Sell: 'Sell',
  'BLP Vault APR': 'BLP Vault APR',
  'Your stake': 'Your Stake',
  rewarded: 'Rewarded',
  'Pending rewards': 'Pending Rewards',
  'Harvest USDT': 'Harvest USDT',
  'Get Insurance': 'Get Insurance',
  Advanced: 'Advanced',
  'Stop Loss': 'Stop Loss',
  'Take Profit': 'Take Profit',
  Slippage: 'Slippage',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo.',
  label: 'label',
  'Entry Price': 'Entry Price',
  'Liq.Price': 'Liq.Price',
  Type: 'Type',
  Spread: 'Spread',
  Time: 'Time',
  Order: 'Order',
  Position: 'Position',
  Mark: 'Mark',
  'Enter a price': 'Enter a price',
  'Price above current price': 'Price above current price',
  'Price below current price': 'Price below current price',
  'Stop loss above current price': 'Stop loss above current price',
  'Stop loss below current price': 'Stop loss below current price',
  'Stop loss below liq.Price': 'Stop loss below liq.Price',
  'Stop loss above liq.Price': 'Stop loss above liq.Price',
  'Take profit below current price': 'Take profit below current price',
  'Take profit above current price': 'Take profit above current price',
  'Take profit below liq.Price': 'Take profit below liq.Price',
  'Take profit above liq.Price': 'Take profit above liq.Price',
  'Slippage should be less than %': 'Slippage should be less than %',
  'Slippage should be more than %': 'Slippage should be more than %',
  Approving: 'Approving',
  'Incorrect network': 'Incorrect network',
  'Create limit order': 'Create limit order',
  'Available Liquidity': 'Available Liquidity',
  titleId: 'titleId',
  Overview: 'Home',
  More: 'More',
  'Your Trades': 'Your Trades {trades}',
  'Your Rank': 'Your Rank {tradeROIRank}',
  'Your price': 'Prize: {tradeROIRankShow}',
  'Your supply': 'Your supply {yourStake}',
  'Your Volume': 'Your Volume {total}',
  AdvancedStopLess:
    'When Index Price reaches {stopLoss} USD, it will trigger Stop Loss, and the estimated PNL will be {stopLossPNL} USD.',
  AdvancedProfit:
    'When Index Price reaches {takeProfit} USD, it will trigger Take Profit, and the estimated PNL will be {tkpPNL} USD.',
  'Your Rewards': 'Your Rewards',
  'BLP Vault': 'BLP Vault',
  'Total Supply': 'Total Supply',
  'BLP Vault balance': 'BLP Vault Balance',
  Entry: 'Entry',
  MinPayUSD: 'Min pay: {min} USD',
  MaxPayUSD: 'Max order size：{max} USD',
  MAXLeverage: 'Max leverage: {max}x',
  MinLeverage: 'Min leverage: {min}x',
  MAXExceeded: 'Max {toSymbol} {pos} exceeded',
  ConnectWallet: 'Connect wallet',
  Index: 'Index',
  Price: 'Price',
  Disconnect: 'Disconnect',
  Perpetual: 'Perpetual',
  Exchange: 'Exchange',
  Copy: 'Copy',
  Download: 'Download',
  Twitter: 'Twitter',
  Share: 'Share',
  Winrate: 'Win Rate',
  Average: 'Average',
  profit: 'profit',
  loss: 'loss',
  Postion: 'Postion',
  Net: 'Net',
  Value: 'Value',
  Close: 'Close',
  Collateral: 'Collateral',
  Edit: 'Edit',
  Limit: 'Limit',
  Cancel: 'Cancel',
  Fund: 'Fund',
  Fee: 'Fee',
  Submit: 'Submit',
  fundFeeInfoP1: 'The funding rate is primarily used to balance the ratio of long positions and short positions.',
  TermsAndConditions: 'BLEX Terms & Conditions',
  MediaKit: 'Media Kit',
  Approve: 'Approve',
  'Connect wallet': 'Connect wallet',
  'Hide Other Pairs': 'Hide Other Pairs',
  Referrals: 'Referrals',
  ReferralsSubTitle: 'Get fee discounts and Rewards rebates through the BLEX referral program.',
  CompetitionsSubtitle: 'Competitions will recognize traders with the highest total profit and trade ROI.',
  Governance: 'Governance',
  Community: 'Community',
  Developers: 'Developers',
  Docs: 'Docs',
  'Contact us': 'Contact us',
  'Who We Are?': 'Who We Are?',
  'Terms of Service': 'Terms of Service',
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.',
  'For trader': 'For traders',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    'Trade perpetuals on exchanges with 100x leverage, 0 slippage and great trading depth using your own wallet!',
  Trade: 'Trade',
  'For vault LP': 'For vault LP',
  'Earn trading fee with in stable coin staking. No impermanent loss.':
    'Earn trading fees in stablecoin staking. no impermanent loss.',
  Earn: 'Earn',
  'Asset Safety': 'Asset Safety',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'Blex is an on-chain dex with perpetual contracts. funds are secure and data is transparent.',
  'Avoid IL': 'Avoid impermanent loss',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.',
  'Value Capture': 'Value Capture',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    'Capture the value of the protocol by staking to earn up to 70% of the transaction fees.',
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.',
  learnMore: 'Learn More',
  'referrals.question': 'How do BLEX referrals work?',
  'referrals.answer1': 'Discounts and rebates will be distributed every wednesday in USDT.',
  'referrals.answer2': 'The referral program offers up to 10% discount for traders, 15% rebates to referrer.',
  'referrals.table.date': 'Date',
  'referrals.table.amount': 'Amount',
  'referrals.table.transaction': 'Transaction',
  'referrals.table.title': 'Rewards Distribution History',
  'referrals.table.no_history': 'No rewards distribution history yet.',
  'referrals.looksLike':
    "Looks like you don't have a referral code to give out. Enter a code below and hit submit to create it on-chain.",
  'referrals.referralCode': 'Referral Code',
  'referrals.tradedVolume': 'Total Volume',
  'referrals.tradersReferred': 'Traders Referred',
  'referrals.totalRebates': 'Total Rebates',
  referrals: 'Referral Codes',
  'referrals.noCodes': 'No referral codes yet.',
  'referrals.ttwitterText':
    'Trade ETH/USD and other top cryptocurrencies with up to 100x leverage directly from your wallet. @blex.io',
  'referrals.codeLenTip': "The referral code can't be more than 20 characters",
  'referrals.title': 'Enter referral code',
  'referrals.subTitle': 'Please input a referral code to benefit from fee discounts',
  'referrals.btnText': 'Submit',
  'referrals.editBtnText': 'Save Changes',
  'referrals.successMsg': 'Referral code updated!',
  'CancelReason.Padding': 'Undefined',
  'CancelReason.Liquidation': 'Liquidation and auto canceled',
  'CancelReason.PositionClosed': 'Position closed and auto canceled',
  'CancelReason.TpAndSlExecuted': 'Tp & Sl executed and auto canceled',
  'CancelReason.SysCancel': 'Error encountered during contract execution',
  'CancelReason.PartialLiquidation': 'Partial liquidation and auto canceled',
  above: 'above',
  below: 'below',
  long: 'long',
  short: 'short',
  tp: 'take profit',
  sl: 'stop loss',
  and: 'and',
  OrderType: 'Order Type',
  'Orders.noPos': 'No open position, order cannot be executed unless a position is opened.',
  'Orders.orderSize': 'Order size is bigger than position, will only be executable if position.',
  'Orders.limitReached':
    'The trigger order has reached the upper limit, and the stop loss / take profit order may not be created.',
  'Orders.stopLoss': 'Stop loss {triggerAbove} Liq.Price, and the stop loss order may not be created.',
  'Orders.orderWithType': 'This order is a {type} order.',
  'Orders.orderSet': 'This order has set {TP} {and} {SL} order.',
  'Orders.pnlText':
    'When index price reaches {triggerPrice} USD, it will trigger {type}, and the estimated PNL will be {PNL} USD.',
  'Orders.tpOrSL': 'Take Profit / Stop loss',
  'Orders.editOrderTitle': 'Edit {type} Order',
  Positions: 'Positions',
  History: 'History',
  _Orders: 'Orders',
  orderPrice: 'Order Price',
  indexPrice: 'Index Price',
  tpSL: 'TP/SL',
  cancelAll: 'Cancel All',
  WARNING: 'WARNING',
  liqPrice: 'Liq. Price',
  enterPrice: 'Enter a price',
  priceAboveCurrent: 'Price above current price',
  stopLossAboveOrder: 'Stop loss above order price',
  stopLossBelowLiq: 'Stop loss below liq.price',
  takeProfitBelowOrder: 'Take profit below order price',
  priceBelowCurrent: 'Price below current price',
  stopLossBelowOrder: 'Stop loss below order price',
  stopLossAboveLiq: 'Stop loss above liq.price',
  takeProfitAboveOrder: 'Take profit above order price',
  priceBelowLiq: 'Price below liq.price',
  priceAboveLiq: 'Price above liq.price',
  updatingOrder: 'Updating order...',
  updateOrder: 'Update order',
  Pair: 'Pair',
  'position.noOpenPositions': 'No Open Positions',
  'position.sharePosition': 'Share Position',
  'position.warinitePositions':
    "This position has a low amount of collateral after deducting borrowing fees, deposit more collateral to reduce the position's liquidation risk.",
  Opening: 'Opening...',
  Closing: 'Closing...',
  editCollateral: 'Edit Collateral',
  entryPrice: 'Entry Price',
  netValue: 'Net Value',
  Market: 'Market',
  Trigger: 'Trigger',
  welcomeTo: 'Welcome to {type}.',
  trustedByUser: 'Trusted by over {uniqueUsersTotal} traders.',
  addedLastWeek: '{money} in the last week',
  tierAndRebate: 'Tier {level} ({amount}% rebate)',
  tierAndDiscount: 'Tier {level} ({amount}% discount)',
  closeIsLongSymbol: 'Close {isLong} {symbol}',
  'Stake USDT and earn rewards from fees generated on the BLEX':
    'Stake USDT and earn rewards from fees generated on the BLEX',
  'The BLP Vault is the counter party to all trades on BLEX':
    'The BLP Vault is the counter party to all trades on BLEX',
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees.",
  'Hello!': 'Hello!',
  '24h Trades': '24h Trades',
  '24h Volume': '24h Volume',
  'No data': 'No Data',
  'Up to 20%': 'Up to 20%',
  'Stake USDT and earn rewadrs from fees generated on the BLEX':
    'Stake USDT and earn rewadrs from fees generated on the BLEX.',
  'Lowest transaction fees': 'Lowest transaction fees',
  'Referral code added!': 'Referral code added!',
  'Create code failed': 'Create code failed',
  'Generate Referral Code': 'Generate Referral Code',
  'No rewards distribution history yet': 'No rewards distribution history yet',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.',
  'You have an active trigger order that could impact this Position':
    'You have an active trigger order that could impact this Position',
  'You have multiple active trigger orders that could impact this Position':
    'You have multiple active trigger orders that could impact this Position',
  'You have an active Limit Order that could impact this Position':
    'You have an active limit order that could impact this position',
  'Creating Order': 'Creating Order',
  Longing: 'Longing',
  Shorting: 'Shorting',
  'Position Size': 'Position Size',
  Leverage: 'Leverage',
  Side: 'Side',
  Increase: 'Increase',
  Long: 'Long',
  Short: 'Short',
  'Price below liq.price': 'Price below liq.price',
  'Price above liq.price': 'Price above liq.price',
  'Max close amount exceeded': 'Max close amount exceeded',
  'Creating order': 'Creating order',
  'Create order': 'Create order',
  'Keep Leverage': 'Keep Leverage',
  Receive: 'Receive',
  Deposit: 'Deposit',
  Withdraw: 'Withdraw',
  'Invalid liq.price': 'Invalid liq.price',
  Withdrawing: 'Withdrawing',
  Depositing: 'Depositing',
  Pay: 'Pay',
  'Bug Bounty': 'Bug Bounty',
  'Submit a Bug': 'Submit a Bug',
  Balance: 'Balance',
  'Collateral: Initial Collateral - Funding Fee': 'Collateral: Initial Collateral - Funding Fee',
  'I have made trades on BLEX, come with me!': 'I have made trades on BLEX, come with me!',
  '+$0.00': '+$0.00',
  ReferralTerms: 'Referral Terms',
  'Confirm Limit Order': 'Confirm Limit Order',
  'Create Order': 'Create Order',
  Prev: 'Prev',
  'Share Time': 'Share Time',
  Next: 'Next',
  'Next Funding Fee': 'Next Funding Fee',
  Countdown: 'Countdown',
  'Waiting for update': 'Waiting for update',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.',
  'Your network is not good, please reconnect your network': 'Your network is not good, please reconnect your network',
  'Total Volume': 'Total Volume',
  'Top{no}': 'Top{no}',
  'Share {no}': 'Share {no}',
  'Futures Trading Competition': 'Futures Trading Competition',
  'Current total trading volume (USDT)': 'Current total trading volume (USDT)',
  'Ends in': 'Ends in',
  'Withdraw collateral': 'Withdraw Collateral',
  'Deposit collateral': 'Deposit Collateral',
  'Open Orders': 'Open Orders',
  'Order History': 'Order History',
  Bar: 'Bar',
  Candle: 'Candle',
  Line: 'Line',
  Area: 'Area',
  'Hollow Candle': 'Hollow Candle',
  Baseline: 'Baseline',
  'Hi-Lo': 'Hi-Lo',
  O: 'O',
  H: 'H',
  L: 'L',
  C: 'C',
  'Event Rules >': 'Event Rules >',
  'Competitions will reward traders based on the volume target and rank.':
    'Competitions will reward traders based on the volume target and rank.',
  'Trade Now': 'Trade Now',
  'Current Total Volume': 'Current Total Volume',
  'TP/SL Keep Leverage': 'TP/SL Keep Leverage',
  Leaderboard: 'Leaderboard',
  ROI: 'ROI',
  Million: 'Million',
  PositionStatusExecuted: 'Executed: Indicates that this order has been successfully completed.',
  PositionStatusRequest:
    'Request: Indicates that the order request was successful, but please be patient while waiting for the order to be executed.',
  PositionStatusCancelled: 'Canceled: Indicates that this order has been canceled.',
  '15-minute waiting period for selling purchased BLP.': '15-minute waiting period for selling purchased BLP.',
  'System is currently upgrading...': 'System is currently upgrading...',
  'Copy Link': 'Copy Link',
  'Referral Code copied to your clipboard': 'Referral Code copied to your clipboard',
  'Total Discount': 'Total Discount',
  'Total Rebate': 'Total Rebate',
  'Total Discounts Earned by this Account as a Trader.': 'Total Discounts Earned by this Account as a Trader.',
  'Total Trading Volume with Valid Referral Codes for this Account.':
    'Total Trading Volume with Valid Referral Codes for this Account.',
  'Total Rebates Earned by this Account as a Referrer.': 'Total Rebates Earned by this Account as a Referrer.',
  'Total Trading Volume of Recommended Traders for this Account.':
    'Total Trading Volume of Recommended Traders for this Account.',
  'Total Number of Traders Recommended by this Account.': 'Total Number of Traders Recommended by this Account.',
  Doc: 'Docs',
  Audit: 'Audit',
  'Transaction Records': 'Transaction Records',
  'Reward Records': 'Reward Records',
  'Total Amount': 'Total Amount',
  'Total Buy Amount': 'Total Buy Amount = Price * Amount  + Fees',
  'Total Sell Amount': 'Total Sell Amount = Price * Amount - Fees',
  'Transaction was cancelled.': "You've rejected granting permission\nPlease check the application.",
  'Market Order Beyond the slippage price and canceled.': 'The market order price exceeds the slippage price.',
  handCancel: 'You have rejected granting permission，Please check the application.',
  'System Cancel': 'System Cancel',
  'Error encountered during contract execution(1000).': 'System Error',
  Skip: 'Skip',
  'Phase II': 'Phase II',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    'Exclusive benefits and rewards for you to unlock, and special rewards for new users!',
  'Will Start In': 'Will Start ends',
  'So Stay Tuned!': 'So Stay Tuned!',
  'Invite Contest': 'Invite Contest',
  'Invite to earn $2,300': 'Invite to earn $2,300',
  'Trading Volume Contest': 'Trading Volume Contest',
  'Trade Futures Volume earn $46,500': 'Trade Futures Volume earn $46,500',
  'Trading ROI Contest': 'Trading ROI Contest',
  'Trade Futures ROI to earn $1,200': 'Trade Futures ROI to earn $1,200',
  'Trading Contest': 'Trading Contest',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.',
  'Detail Rules': 'Detail Rules',
  'When Total Trading Volume Reaches': 'When Total Trading Volume Reaches',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get \n{value} USDT. The reward is limited to the first 500 valid invitations, first come first served.',
  'Note: The maximum reward for an individual user is $20 USDT.':
    'Note: The maximum reward for an individual user is {value}.',
  'Invite Now': 'Invite Now',
  'ROI Rank': 'ROI Rank',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    'During the event, {value} will reward users according to their future trading profitability (ROI) rank. \nBoth the {value1} and top {value2} will get  {value3} each in this session.',
  Notices: 'Notices',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    '1.Users must meet the requirements during the event to be eligible for the rewards.',
  '2.The rewards will be issued within 7 working days after the event.':
    '2.The rewards will be issued within {value}  working days after the event.',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '3.The trading volume only includes Futures trading.  {value}',
  '4.Users need to accumulate at least 5U to win.': '4.Users need to accumulate at least  {value}  to win.',
  '5.No spam is allowed, or you will be disqualified.': '5.No spam is allowed, or you will be disqualified.',
  '6.BLEX reserves the right to the final interpretation of this event.':
    '6.BLEX reserves the right to the final interpretation of this event.',
  '7.If you have any questions, please contact our admin via':
    '7.If you have any questions, please contact our admin via',
  'Your ROI': 'Your ROI',
  'ROI = (Profit + UnProfit) / Cum.Collateral': 'ROI = (Profit + UnProfit) / Cum.Collateral',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral':
    'Cum.Collateral = Total Opening position + Total Deposit Collateral',
  'Volume = Total Size (Open Size + Close Size)': 'Volume = Total Size (Open Size + Close Size)',
  'Trading Volume = Opened Positions + Closed Positions.': 'Trading Volume = Opened Positions + Closed Positions.',
  'discord or telegram': 'discord or telegram.',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.':
    'In addition, the {value} inviting users can get an additional {value1} reward after the event ends.',
  '3.The trading volume only includes Futures trading.': '3.The trading volume only includes Futures trading.',
  Top3: 'Top3',
  'Bottom 3': 'Bottom 3',
  'no list data': 'No list data',
  'mail notice': 'Email Notification',
  'mail info Add':
    'The wallet center has added an "Email Notification" feature, which, when enabled, allows you to receive timely updates on position-related information.',
  'open mail subscrip': 'Here you can also activate the "Email Subscription" feature.',
  'got it': 'I got it.',
  'provide mail account':
    'Please provide your frequently used email address, and we will send you position-related alert notifications at any time.',
  'please enter email account': 'Please enter your email address.',
  'error style re enter': 'The email format is incorrect. Please enter again.',
  'confirm open': 'Confirm to enable',
  'close mail notice': 'Disable email notifications',
  'close mail info':
    "You won't receive timely position-related alert notifications after disabling email notifications.",
  'comfir close': 'Confirm to disable',
  considerate: 'Consider it',
  'open info': 'Enable email notifications to receive your position information promptly',
  'please enter mail': 'Please enter your email address',
  'unvaild mail': 'The email format is incorrect. Please enter again',
  'open mail success': 'Email notifications successfully enabled. Please check your email at any time',
  'close mail success':
    'You have successfully unsubscribed from email notifications. The platform will no longer send you position-related alert notifications',
  next: 'Next',
  'Internal JSON-RPC error.': 'Internal JSON-RPC error.',
  'Market Order Beyond the slippage price and canceled': 'Market Order Beyond the slippage price and canceled.',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    'The index price is for reference only, and the actual transaction price prevails.',
  'PNL after fees': 'PNL after fees = PNL - Position Fee - FundingFee -  Execution Fee - Liquidation Fee',
  'Historical Peak': 'Historical Peak',
  'Super Referrers': 'Traders Giving',
  'Contact us for more discounts': 'Contact us for more discounts',
  'PNL / ROI': 'PNL / ROI',
  'Target Price': 'Target Price',
  'Calculations are for reference only.': 'Calculations are for reference only.',
  Calculator: 'Calculator',
  Calculate: 'Calculate',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.':
    'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.',
  'PNL After Fees': 'PNL After Fees',
  'Unable to reach this return rate.': 'Unable to reach this return rate.',
}
