export default {
  'Execution Fee': 'İşlem Ücreti',
  'This rate of return cannot be achieved': 'Bu getiri oranına ulaşılamıyor.',
  'Open Fee': 'Açılış İşlem Ücreti',
  Indicators: 'Göstergeler',
  Result: 'Sonuç',
  'Exit Price': 'Çıkış Fiyatı',
  Tradingview: 'Tradingview',
  'Initial Collateral': 'İlk Teminat',
  'No Open Positions': 'Açık Pozisyon Yok',
  'Confirm Close': 'Onayla',
  'Are you sure you want to close all positions? lt may require multiple wallet':
    'Şimdi tüm pozisyonları kapatmak istediğinizden emin misiniz? Birden fazla cüzdan gerekebilir.',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)':
    'Cüzdanı bağlayarak, {to} ile aynı fikirde olduğunuzu kabul edersiniz. (Son güncelleme tarihi: 31/05/2023)',
  'BLEX Arbitrum Goerli Testnet': 'BLEX Arbitrum Goerli Testnet',
  'Keep Leverage {lev}x': "Leverajı {lev}x'te Tut",
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    "BLP Kasası, BLEX'teki tüm işlemlerin karşı tarafında yer alır ve bu sayede platformun oluşturduğu ücretlerin %100'ünü alır.",
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.':
    'Likidite sağlamak ve platform ücreti dağıtımından %100 pay kazanmak için BLP satın alın.',
  'View status': 'Durumu Görüntüle',
  'Please select a network': 'Lütfen bir ağ seçin',
  Network: 'Ağ',
  'Receive test coins': 'Test jetonları al',
  Language: 'Dil',
  connected_to: '{chainName} bağlandı',
  Error: 'Hata',
  'hide error': 'Hata gizle',
  'show error': 'Hata göster',
  'The system is currently busy': 'Sistem şu an meşgul',
  'Transaction sent.': 'Talep Gönderildi.',
  'Transaction completed!': 'Talep başarıyla gönderildi. Lütfen işlemin gerçekleşmesini sabırla bekleyin.',
  'Close All': 'Tümünü Kapat',
  status: 'Durum',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    'İşlem modülünde, uzun veya kısa gitmek için bir çift seçerek ve satın almak istediğiniz miktarı ve miktarı ayarlayarak bir pozisyon açabilirsiniz. Bir pozisyon açtıktan sonra işlem kaydında pozisyonunuzla ilgili tüm bilgileri görebilirsiniz.',
  'BLEX {testnet} Testnet': 'BLEX {testnet} Test Ağı',
  'Website Pickup': 'Web Sitesini Al',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    'Bu hafta Arbitrum Goerli Testnet üzerinde yeni testnet sözleşmeleri dağıtılacak, testnet verileri temizlenecektir. Testnet kullanıcıları için, testnet fonlarını pozisyon teminatında tutmak isteniyorsa, pozisyonlar önceden kapatılabilir.',
  'Edit Referral Code': 'Davet Kodunu Düzenle',
  'Ends at': 'Bitiş',
  'Starts in': 'Başlangıç',
  'Decentralized Perpetual Exchange': 'Merkezi Olmayan Sürekli Takas',
  'Please select a language': 'Lütfen bir dil seçin',
  USDT: 'USDT',
  'Funding Fee / Day:': 'Fonlama Ücreti / Gün:',
  'Using Friendly': 'Kullanıcı Dostu',
  'Trading Smooth': 'Kusursuz Ticaret',
  copy: 'Link panoya kopyalandı.',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.':
    "BLE stake edin, Escrowed BLEX ödüllerini ve platformdaki masraf dağıtımının %x'ini kazanın.",
  'BLE Vault APR': 'BLE Vault APR',
  'Coming Soon': 'Yakında',
  Telegram: 'Telegram',
  'in the last 24h': 'son 24 saatte',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.':
    'Likidite sağlamak ve platform ücreti dağıtımından %100 pay kazanmak için BLP satın alın.',
  Referral: 'Referans',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    "Sayın kullanıcılar, kendi varlıklarınızı kontrol ederken KYC gerekmeksizin BLEX'te işlem stratejinizi uygulayabilirsiniz. Hemen merkeziyetsiz sürekli sözleşme ticaretinin pürüzsüz yolculuğuna başlayın!",
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    'Bir referans kodu ile bağlandığınızda veya referans kodunuz ile birileri bağlandığında, ilgili geri ödeme bonuslarını ve geri ödeme indirimlerini alabilirsiniz. Bonus veya indirim her hafta otomatik olarak cüzdanınıza ödenecektir.',
  Staking: 'Staking',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    'Platformdan $BLP gelir eklenmiş ürünleri satın alabilir ve satın aldıktan sonra geliri istediğiniz zaman cüzdanınıza çekebilirsiniz.',
  Trading: 'Ticaret Hacmi',
  Connections: 'Bağlantılar',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    'BLEX tarafından desteklenen bir cüzdanı seçmek için gezinme çubuğundaki "Cüzdanı Bağla" düğmesini tıklayın. Cüzdanları bağladıktan sonra BLEX tarafından desteklenen bir ağı seçin ve BLEX\'in tadını çıkarın!',
  'No Open Orders': 'Açık Emir Yok',
  'BLEX T&C': 'BLEX T&C',
  'No History': 'Geçmiş yok',
  Hide: 'Gizle',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    "Bir referans kodu bağlayarak ticaretlerinizde %10'a kadar indirimi alabilirsiniz. Ticaret indirimleri her Çarşamba USDT olarak iade edilmektedir.",
  'Receive Discounts': 'İndirimleri Alın',
  'Trading Discount': 'Ticaret İndirimi',
  'Bind Referral Code': 'Referans Kodunu Bağla',
  'Input a referral code to benefit from trade discount.':
    'Ticaret indirimlerinden faydalanmak için bir referans kodu girin.',
  'Get discounts on transaction fees when you make a trade.': 'Ticaret yaparken işlem ücretlerinden indirimler alın.',
  'The discounts will be paid out in USDT every Wednesday.': 'Bu indirimler her Çarşamba USDT olarak ödenmektedir.',
  'Sorry, the page you visited does not exist': 'Üzgünüz, ziyaret ettiğiniz sayfa mevcut değil',
  Successful: 'Başarılı',
  'Bridge ETH to Arbitrum': "Arbitrum'a ETH Köprüle",
  'There is not enough ETH in your account on Arbitrum to send this transaction.':
    'Bu işlemi göndermek için yeterli ETH Arbitrum cüzdanınızda bulunmamaktadır.',
  'Transaction failed due to RPC error.': 'İşlem RPC hatası nedeniyle başarısız.',
  'Please try changing the RPC url in your wallet settings.':
    "Lütfen cüzdan ayarlarınızda RPC URL'sini değiştirmeyi deneyin。",
  'Transaction failed': 'İşlem başarısız',
  success: 'Başarılı',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.':
    "Referans kodunuzu paylaşın ve %15'e kadar olan ticaret geri ödemesini alın. Geri ödemeler her Çarşamba USDT olarak ödenecektir.",
  'Referral Code:': 'Referans Kodu:',
  'Index Perpetual Exchange': 'Sürekli Vadeli Borsa Endeksi',
  Request: 'Talep Et',
  'Drops are limited to1 request in 8 hours.': 'Droplar 8 saatte 1 istekle sınırlıdır',
  FollowTwitter: "Daha fazla {marketname} için Twitter ve Telegram'ı takip edin.",
  'Wallet Address:': 'Cüzdan Adresi:',
  'Total Balance (USDT)': 'Toplam Bakiye (USDT)',
  'Short Open Interest: {value}': 'Short Pozisyon Açık Faiz: {value}',
  'Long Open Interest: {value}': 'Long Pozisyon Açık Faiz: {value}',
  'Open Interest Balance': 'Açık Faiz Dengesi',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    'BLEX merkeziyetsiz bir borsa olarak kullanıcı adı veya şifre gerektirmeden işlem yapmaya olanak tanır. Temel prensibi güvenli, adil ve etkili bir endeks fiyat besleme modeline dayanır.',
  'How do BLEX Trade work?': "BLEX'te nasıl ticaret yapılır?",
  LONG: 'LONG',
  SHORT: 'SHORT',
  'Price Above Liq. Price': 'Lik. Fiyatının Altında Fiyat',
  'Price Below Liq. Price': 'Lik. Fiyatının Üzerinde Fiyat',
  Slider: 'Slider',
  Customize: 'Düzenle',
  'Your wallet is not connected to': "Cüzdanınız {chainName}'e bağlı değil",
  'Sell BLP': 'BLP Sat',
  'Buy BLP': 'BLP Satın Al',
  'Selling...': 'Satılıyor…',
  'Buying...': 'Alınıyor…',
  'Update Notice': 'Güncelleme Bildirimi',
  'Considers settled (closed) positions': 'Kapatılmış pozisyonlar dikkate alınır',
  'Fees are not factored into PnL': "İşlem ücretleri K/Z'ye dahil edilmez.",
  Volume: 'Hacim',
  'Last Month': 'Son 1 Ay',
  'Last 2 Months': 'Son 2 Ay',
  'Last 3 Months': 'Son 3 Ay',
  Users: 'Kullanıcılar',
  'Users Actions': 'Kullanıcıların Hareketleri',
  'Traders Profit vs. Loss': 'Kullanıcıların Kârı vs Zararı',
  'Traders Net PnL': 'Kullanıcı Net K/Z',
  'Total Users': 'Toplam Kullanıcı',
  'BLP Pool': 'BLP Havuzu',
  'Total Fees': 'Toplam masraf',
  Analytics: 'Analitik',
  '+$0': '+$0',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    "BLP Kasası, BLEX'teki tüm işlemlerin karşı tarafında yer alır ve bu sayede platformun oluşturduğu ücretlerin %100'ünü alır.",
  Status: 'Durum',
  'What is BLEX':
    "BLEX, merkeziyetsiz bir perpetual borsa protokolü kurmayı hedeflemektedir. Ekibin üyeleri önde gelen merkezi borsalardan, tanınmış hedge fonlarından ve blockchain şirketlerinden oluşmaktadır. BLEX'in yönetimi BLEX DAO tarafından belirlenecektir. BLEX, Singapur ve Hong Kong'da ofislere sahiptir ve BLEX DAO üyeleri dünya genelinde uzaktan çalışma yoluyla katkı sağlamaktadır. Sizi de BLEX'in bir üyesi olarak karşılamaktan mutluluk duyarız.",
  earnTip:
    "Not: Hem kullanıcılar hem de likidite sağlayıcıları kayıp riskine sahiptirler, BLEX'e katılmadan önce detayları dikkatlice öğrenmeye dikkat edin.",
  'Media Kit': 'Medya Kiti',
  BugBounty: 'Bug Bounty',
  'I have understood': 'Anladım.',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our':
    "Çin IP adresinden BLEX'e erişmeye çalıştığınızı fark ediyoruz, politikalarımıza göre",
  ', we are unable to serve any users from an {regionName} IP.':
    'Çin IP adresinden gelen kullanıcılara hizmet veremiyoruz.',
  'footer.ifYouHaveAnyQuestions':
    'Ürünlerimizin kullanımı hakkında herhangi bir sorunuz varsa size yardımcı olmaktan mutluluk duyarız, lütfen bizimle iletişime geçin.',
  'footer.products': 'Ürünler',
  'Referral Terms': 'Referans Şartları',
  'footer.reservedRights': '© 2023 BLEX. Tüm hakları saklıdır.',
  'Welcome to BLEX': "BLEX'e Hoş Geldiniz",
  'wallet.connect': 'Oturum açmak veya hesap oluşturmak için bağlanın',
  'wallet.lastUpdated':
    'Cüzdanınızı bağlayarak, BLEX Şartlarını ve Koşullarını kabul edersiniz. (Son güncelleme tarihi: 31/05/2023)',
  'Perpetual Exchange': 'Sürekli Vadeli Borsa',
  'Perpetual Trading': 'Sürekli Vadeli Ticaret',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.':
    "Staking'i kullanarak LP'ler işlem ücretlerinin %70'ine kadarını kazanabilir ve protokolün değerini yakalayabilirler.",
  'Earn Now': 'Şimdi Kazanın',
  'View more': 'Daha fazlasını görüntüle',
  'Explore The Endless': 'BLEX ile Sonsuz',
  'Possibilities With BLEX': 'Olanakları Keşfedin',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.':
    'BLEX, sürekli vadeli işlemler hizmeti veren on-chain merkeziyetsiz bir borsadır ve güvenli fonlar ile şeffaf veri akışını garanti eder.',
  'Smooth Trading': 'Kusursuz Ticaret',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    'Kullanıcılar endeks fiyatını kullanarak sıfır spread ile pozisyon açabilirler, bu olağanüstü derinliği LP kasası sağlar.',
  'Get Started': 'Başlarken',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    'Web ve mobil uygulama kullanılarak, kolay cüzdan entegrasyonu ile ticaret yapılabilir. Katılmak için sadece tek bir basit adım yeterli.',
  'Always at your side': 'Her Zaman Yanınızda',
  'Join the BLEX community now!': 'Şimdi BLEX topluluğuna katılın!',
  Mint: 'Mint',
  'We are continuously launching new perpetual contracts markets.':
    'Devamlı olarak yeni sürekli vadeli kontrat piyasası başlatıyoruz.',
  'Stake BLE and BLP to earn rewards.': 'Ödül kazanmak için BLE ve BLP stake edin.',
  'Get test tokens': 'Test token talep et.',
  'Wallet Address': 'Cüzdan Adresi',
  'Share Revenue': 'Gelir Paylaşımı',
  'Txn failed.': 'Txn başarısız.',
  ', we are unable to serve any users from an ': 'Herhangi bir kullanıcıya hizmet veremiyoruz.',
  Switch: 'Değiştir',
  'Connect Wallet': 'Cüzdan Bağla',
  'Drops are limited to 1 request in 8 hours.': 'Droplar 8 saatte 1 istekle sınırlıdır.',
  'Connect your wallet': 'Cüzdanını bağla',
  Home: 'Anasayfa',
  'Bug bounty': 'Hata Ödül Programı',
  Test: 'Test',
  'Considers Settled (Closed) Positions': 'Tamamlanan (Kapanmış) Pozisyonları Dikkate Alınır',
  'Fees Are Not Factored Into PnL': "İşlem ücretleri K/Z'ye dahil edilmez.",
  'The total number of positions opened and closed during the event':
    'Etkinlik sırasında açılan ve kapatılan toplam pozisyon sayısı.',
  Days: 'Günler',
  Hours: 'sa',
  Minutes: 'dk',
  Seconds: 'sn',
  PNL: 'K/Z',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.':
    'BLEX hata ödül programı şimdi aktif! Kilit bir hata raporu için 100,000 dolara kadar ödül.',
  'Assets Under Management': 'Yönetilen Varlıklar',
  Service: 'Hizmet',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.':
    "BLE stake edin, Escrowed BLEX ödüllerini ve platform ücret dağıtımının %30'unu kazanın.",
  'Staking to Earn': 'Kazanmak için Stake Edin',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    "Referans kodunu bağlayarak %10'a kadar ticaret indirimi alın. Ticaret indirimleri her Çarşamba USDT olarak geri ödenecektir.",
  'Create referral code and start earning rebates!': 'Referans kodu oluşturun ve iadeleri kazanmaya başlayın!',
  'Invite User': 'Davet Et',
  'Share your referral code on social media platforms such as Twitter and Facebook.':
    'Refarans kodunuzu X ve Facebook gibi sosyal medya platformlarında paylaşın.',
  'Earn Rebates': 'İadeleri kazanın',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    'Kullanıcıları ticaret yapmaya davet ettiğinizde, kullanıcılar düşük ticaret ücretlerinden faydalanır ve siz de ticaret iadeleri alırsınız.',
  Referrers: 'Yönlendirenler',
  'Your Active Referral Code': 'Aktif Referans Kodunuz',
  'Please input a referral code to benefit from fee discounts.':
    'İşlem ücreti indirimlerinden faydalanmak için bir referans kodu girin.',
  'Referral code updated failed.': 'Referans kodu güncelleştirilemedi',
  'Adding referral code failed.': 'Referans kodu ekleme başarısız oldu',
  'Submitting...': 'Gönderiliyor…',
  'Checking code...': 'Kod kontrol ediliyor…',
  Action: 'Eylem',
  'Creating...': 'Oluşturuluyor…',
  'Create code failed.': 'Referans kodu oluşturulamadı',
  'Create Referral Code': 'Referans Kodu Oluştur',
  'Rebates earned by this account as a trader.': 'Kullanıcıların ticaretten kazandığı iadeler.',
  'Volume traded by this account with an active referral code.':
    'Bu hesap tarafından aktif bir referans kodu ile yapılan işlem hacmi.',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    'Bu, bağlı olduğunuz tavsiye edenin seviyesini ve aç/kapa ücretlerinde alacağınız indirim yüzdesini temsil eder; bu indirim her Çarşamba hesabınıza airdrop yapılır.',
  'Total Claimable Reward': 'Toplam Talep Edilebilir Ödül',
  'Claim all': 'Hepsini talep et',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.':
    "BLE stake edin, Escrowed BLEX ödüllerini ve platform ücret dağıtımının %30'unu kazanın.",
  'Your Wallet': 'Cüzdanınız',
  'Your Staked': 'Stake Edilen Miktar',
  'Total Staked': 'Toplam Stake Edilen Miktar',
  'Buy BLE': 'BLE Satın Al',
  Stake: 'Stake',
  Unstake: 'Unstake',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.':
    "USDT ile BLP satın alaraj likidite sağlayabilirsiniz ve bu sayede platform ücretlerinin %70'ini kazanırsınız.",
  'BLE Points': 'BLE Puanları',
  'The fuel for boosting esBLE to BLE.': "esBLE'yi BLE'ye yükseltmek için gerekli olan yakıt.",
  'Buy BPT': 'BPT Satın Al',
  'Escrowed BLE': 'Escrowed BLE',
  'Convert esBLE to BLE through Boost and Vest.': "Boost ve Vest'i kullanarak esBLE'yi BLE'ye çevir.",
  Boost: 'Boost',
  Vest: 'Vest',
  'Enjoy BLP Rewards': 'BLP Ödüllerinden Faydalanın',
  'Total BLP amount': 'Toplam BLP Miktarı',
  'Quantity of NFTs.': 'NFT adedi.',
  Expand: 'Genişlet',
  'Mint successful': 'Mint başarılı',
  'Hurry up stake and enjoy the Rewards.': 'Hemen stake edin ve Ödüllerin tadını çıkarın.',
  Done: 'Tamamlandı',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!':
    'Bu NFT sayesinde BLP ödüllerinin ve iki kat BPT gelirinin tadını çıkarabilirsiniz!',
  'Your Stake': 'Stake Edilen Miktar',
  'Stop loss': 'Zarar durdur',
  'Take profit': 'Kar al',
  'Creating Order...': 'Emir oluşturuluyor…',
  'Longing...': 'Longing…',
  'Shorting...': 'Shorting…',
  'Liq. Price': 'Lik. Fiyatı',
  'View Chart': 'Grafiği Görüntüle',
  Export: 'Dışa Aktar',
  price: 'Fiyat',
  'Net Value': 'Net Değer',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee':
    'Net Değer: İlk Teminat + K/Z - Fonlama Ücreti - Kapama Ücreti',
  Liq: 'Lik',
  'Price below liq. price': 'Likitasyon fiyatının altındaki fiyat',
  'Price above liq. price': 'Likitasyon fiyatının üstündeki fiyat',
  'Slippage should be less than': 'Slipaj daha düşük olmalı',
  'Slippage should be more than': 'Slipaj daha yüksek olmalı',
  'Creating order...': 'Emir oluşturuluyor…',
  'Closing...': 'Kapatılıyor…',
  'Index Price:': 'Endeks Fiyatı:',
  'Close:': 'Kapat:',
  'Available:': 'Kullanılabilir:',
  'Withdrawing...': 'Çekiliyor…',
  'Depositing...': 'Yatırılıyor…',
  MAX: 'MAKS',
  Amount: 'Miktar',
  'Size:': 'Boyut:',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.':
    "BLEX {chainName} 'a hoş geldiniz. Lütfen önce testToken'ı almayı unutmayın.",
  All: 'Tam',
  'Open Long': 'Açık Uzun',
  'Open Short': 'Açık Kısa',
  'Close Long': 'Kapalı Uzun',
  'Close Short': 'Kapalı Kısa',
  Liquidation: 'Tasfiye',
  'Partial Liquidation': 'Kısmi Tasfiye',
  'Partial liquidation': 'Kısmi Tasfiye',
  'Withdraw Collateral': 'Teminat Çekme',
  'Deposit Collateral': 'Teminat Yatırma',
  '5m': '5 dakika',
  '15m': '15 dakika',
  '1h': '1 saat',
  '4h': '4 saat',
  '1d': '1 gün',
  Executed: 'Yürütüldü',
  Canceled: 'İptal Edildi',
  Original: 'Orijinal',
  TradingView: 'TradingView',
  Currency: 'Para Birimi',
  'Balance:': 'Bakiye:',
  'The BLP Vault is the counter party to all trades on BLEX.':
    "BLP Kasası, BLEX'teki tüm işlemlerin karşıt pozisyonunda yer alır.",
  'How do BLEX rewards work?': "BLEX'in ödül sistemi nasıl işliyor?",
  tradesNoHistory: 'Geçmiş Yok',
  'How do BLEX Earn work?': "BLEX'in ödül sistemi nasıl işliyor?",
  'About Blex': 'Blex Hakkında',
  'Connect your wallet to continue': 'Cüzdanı Bağla',
  Filter: 'Filtrele',
  'No Record': 'Kayıt Yok',
  'Go trade now!': 'Şimdi İşlem Yap!',
  Cancle: 'İptal et',
  'Competitions 🔥': 'Yarışmalar',
  Continue: 'Devam Et',
  Competitions: 'Yarışmalar',
  Decentralized: 'Merkeziyetsiz',
  'The index price retrieval is incorrect, please try again later.':
    'Endeks fiyat alımı yanlış, lütfen daha sonra tekrar deneyin.',
  'User friendly': 'Kullanıcı Dostu',
  'Event Details>>': 'Etkinlik Detayları>>',
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    "BLEX Arbitrum mainnet lansmanı 6 Ağustos'ta! Şimdi yerinizi rezerv edin ve toplam $10,000 ödül havuzunu paylaşın.",
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    'Tebrikler! Rezervasyon başarılı! Son $10,000 USDT ödülünü paylaşmak için devam edin!',
  'Reservation Success': 'Rezarvasyon Başarılı',
  Reserve: 'Rezerve Et',
  'Countdown to the launch': 'Lansmana geri sayım',
  Connect: 'Bağlan',
  'Enter Referral code': 'Referans kodu gir',
  'Open Interest': 'Açık Faizler',
  AnalyticsDesc: '{time} tarihinde blok {block} güncellendi.',
  '24h Change': '24 Saatlik Değişim',
  '24h High': '24 Saatlik En Yüksek',
  '24h Low': '24 Saatlik En Düşük',
  'Buy / Sell BLP': 'BLP Satın Al / Sat',
  IMakeTrades: "BLEX'te {value} işlem yaptım, benimle gel!",
  'Index Price': 'Endeks Fiyatı:',
  'Share Position': 'Pozisyonu Paylaş',
  'PnL After Fees:': 'İşlem Ücretleri sonrası K/Z:',
  'Open Fee {value}': 'Açılış İşlem Ücreti: {value}',
  'Close Fee {value}': 'Kapanış İşlem Ücreti: {value}',
  'Funding Fee {value}': 'Fonlama Ücreti: {value}',
  'PnL {value}': 'K/Z: {value}',
  'Initial Collateral {value}': 'İlk Teminat: {value}',
  'Use the edit collateral icon to deposit or withdraw collateral.':
    'Teminat yatırmak veya çekmek için düzenleme simgesini kullanın.',
  'Funding Fee:': 'Fonlama Ücreti:',
  'Collateral:  Initial Collateral - Funding Fee': 'Teminat: İlk Teminat - Fonlama Ücreti',
  'Initial Collateral:': 'İlk Teminat:',
  ConfirmLong: 'Long {symbol}',
  ConfirmShort: 'Short {symbol}',
  Confirm: 'Onayla',
  Networks: 'Ağlar',
  ExecutionFee: 'İşlem Ücreti: {fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    'İşlem ücretler, yalnızca emir gerçekleştirildiğinde veya geçersiz bir işlem nedeniyle iptal edildiğinde tahsil edilir.',
  OpenFeeLabel: 'Açılış İşlem Ücreti: {fee}',
  CloseFeeLabel: 'Kapanış İşlem Ücreti: {fee}',
  FundingFee: 'Fonlama Ücreti: {fee}',
  Fees: 'İşlem Ücretleri',
  Size: 'Boyut',
  Available: 'Kullanılabilir',
  MinResidualCollateralUSD: 'Minimum artık teminat: {USD} USD',
  LeftoverNetValue: "${cleanFee} 'ın altında kalan net değer",
  LeftoverCollateral: "Teminat ${cleanFee} 'in altında",
  AcceptMinimum: 'Minimum kabul et ve {action}',
  ExistingIncrease: 'Mevcut birden fazla artırma {isLong} {toSymbol} limit emriniz bulunuyor.',
  ExistingPosition: 'Zaten {isLong} bir pozisyonunuz bulunuyor ve bu mevcut pozisyonunuzun boyutunu artıracak.',
  ConfirmOrderShort: '{toSymbol} Short Pozisyonu Onayla',
  ConfirmOrderLong: '{toSymbol} Long Pozisyonu Onayla',
  'Connected with': 'Şununla bağlandı',
  'View on Explorer': "Explorer'da görüntüle.",
  'Txn failed': 'Txn başarısız',
  View: 'Görüntüle',
  text: 'metin',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    'Henüz bir varlığa sahip değil misiniz? Başka bir ağdan ETH köprüleyebilir veya bir borsadan ETH transfer edebilirsiniz.',
  Homepage: 'Anasayfa',
  'Trading Volume': 'Ticaret Hacmi',
  Open: 'Açık',
  Rank: 'Sıralama',
  Account: 'Hesap',
  '': '',
  'Cum.Size': 'Küm.Boyut',
  'Cum.Collateral': 'Küm.Teminat',
  Prize: 'Ödül',
  Reward: 'Kazan',
  'Total Profit': 'Toplam Kar',
  'The total volume accumulated between the activity': 'Aktiviteler arasında biriken toplam hacim.',
  'BLP Supply': 'BLP Arzı',
  'The total BLP supply accumulated between the activity': 'Aktiviteler arasında biriken toplam BLP arzı',
  'Prize Pool': 'Ödül Havuzu',
  'The estimated amount distirbuted to all top traders by competition end results':
    'Yarışma sonuçlarına göre en iyi kullanıcılar arasında dağıtılan tahmini miktar.',
  Trades: 'Trades',
  'Rank List': 'Sıralama',
  'Profit Rank': 'Kar Sıralaması',
  Profit: 'Kar',
  'Trade ROI Rank': 'İşlem ROI Sıralaması',
  'Trade ROI': 'İşlem ROI',
  'Trading Data': 'Ticaret Verileri',
  'Total volume': 'Toplam hacim',
  'Fees Paid': 'Ödenen işlem ücretleri',
  'Trading Fees': 'İşlem Ücreti',
  'Open/Close Fee': 'Açma/Kapama Ücreti',
  'Funding Fee': 'Fonlama Ücreti',
  'Read More': 'Daha fazla bilgi',
  'Average profit': 'Ortalama kar',
  'Average loss': 'Ortalama zarar',
  'Address copied to your clipboard': 'Adres panoya kopyalandı',
  Traders: 'Kullanıcılar',
  'Enter referral code': 'Referans kodu gir',
  'Please input a referral code to benefit from fee discounts':
    'İşlem ücreti indirimlerinden faydalanmak için bir referans kodu girin.',
  'Save Changes': 'Değişiklikleri Kaydet',
  'Referral code updated!': 'Referans kodu güncellendi!',
  'Referral code updated failed': 'Referans kodu güncelleştirilemedi',
  'Adding referral code failed': 'Referans kodu ekleme başarısız oldu',
  'Same as current active code': 'Referans kodu şimdikiyle aynı',
  Submitting: 'Gönderiliyor',
  'Checking code': 'Kod kontrol ediliyor',
  'Referral code does not exist': 'Referans kodu mevcut değil',
  'Referral link copied to your clipboard': 'Referans linki panoya kopyalandı.',
  Creating: 'Oluşturma',
  'Enter a code': 'Kod gir',
  'Code already taken': 'Kullanılmış kod',
  Create: 'Oluştur',
  title: 'başlık',
  desc: 'açıklama',
  'Total Trading Volume': 'Toplam İşlem Hacmi',
  'Volume traded by this account with an active referral code':
    'Bu hesap tarafından etkin referans kodları aracılığıyla gerçekleştirilen toplam hacim',
  'Total Traders Referred': 'Tavsiye Edilen Toplam Tüccar Sayısı',
  'Rebates earned by this account as a trader': 'Bu hesap tarafından',
  'Total Rebates': 'Toplam İadeler',
  'Tier 1 (5% rebate)': 'Seviye 1 (5% iade)',
  'Edit Refferal code': 'Referans kodunu düzenle',
  'Active Referral Code': 'Geçerli Tavsiye Kodu',
  'Tier 1 (5% discount)': 'Seviye 1 (5% indirim)',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    "Pozisyon açma/kapama işlem ücretlerinizde %5 indirim alacaksınız, bu indirim her Çarşamba hesabınıza airdrop'lanacaktır.",
  'Tier 1': 'Seviye 1',
  Rewards: 'Kazan',
  'Stake USDT and earn rewards from fees generated on the BLEX.':
    'USDT stake et ve BLEX üzerinde oluşan işlem ücretlerinden kazanç elde et.',
  'Learn More': 'Daha Fazla',
  'Enter an amount': 'Miktar gir',
  'Insufficient USDT balance': 'Yetersiz USDT bakiyesi',
  'Insufficient BLP balance': 'Yetersiz BLP bakiyesi',
  Buy: 'Satın al',
  Sell: 'Sat',
  'BLP Vault APR': 'BLP Kasası Yıllık Faiz Oranı',
  'Your stake': 'Stake Edilen Miktar',
  rewarded: 'ödüllendirilen',
  'Pending rewards': 'Bekleyen Ödüller',
  'Harvest USDT': 'USDT topla',
  'Get Insurance': 'Sigortala',
  Advanced: 'Gelişmiş',
  'Stop Loss': 'Zarar Durdur.',
  'Take Profit': 'Kar Al.',
  Slippage: 'Slipaj',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    "Volatil fiyat hareketlerinde düşük slipaj ( örn. 0.5%'ten düşük ) kullanmak emirlerin başarısız olmasına sebep olabilir.",
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    "BLEX'te USD gibi stabil kripto paralar ile işlem yaparken, görüntülenen açık pozisyon miktarları gerçek coin miktarına dayalı olarak hesaplanır. Değerler son fiyata göre değişir.",
  label: 'etiket',
  'Entry Price': 'Açık Emirler',
  'Liq.Price': 'Lik. Fiyatı',
  Type: 'Tür',
  Spread: 'Spread',
  Time: 'Zaman',
  Order: 'Emir',
  Position: 'Pozisyon',
  Mark: 'İşaret',
  'Enter a price': 'Fiyat gir',
  'Price above current price': 'Şimdiki fiyatın üstündeki fiyat',
  'Price below current price': 'Şimdiki fiyatın altındaki fiyat',
  'Stop loss above current price': 'Şimdiki fiyatın üstünde zararı durdur',
  'Stop loss below current price': 'Şimdiki fiyatın altında zararı durdur',
  'Stop loss below liq.Price': 'Lik. fiyatının altında zararı durdur',
  'Stop loss above liq.Price': 'Lik. fiyatının üstünde zararı durdur',
  'Take profit below current price': 'Şimdiki fiyatın altında kar al',
  'Take profit above current price': 'Şimdiki fiyatın üstünde kar al',
  'Take profit below liq.Price': 'Lik. fiyatının altında kar al',
  'Take profit above liq.Price': 'Lik. fiyatının üstünde kar al',
  'Slippage should be less than %': "Slipaj %'den küçük olmalı",
  'Slippage should be more than %': "Slipaj %'den büyük olmalı",
  Approving: 'Onaylamak',
  'Incorrect network': 'Yanlış ağ',
  'Create limit order': 'Limit Emri Oluştur',
  'Available Liquidity': 'Kullanılabilir Likidite',
  titleId: 'titleId',
  Overview: 'Genel Bakış',
  More: 'Daha fazlası',
  'Your Trades': 'Ticaretlerin {trades}',
  'Your Rank': 'Sıralamanız {tradeROIRank}',
  'Your price': 'Ödül: {tradeROIRankShow}',
  'Your supply': 'Yatırılan Miktar {yourStake}',
  'Your Volume': 'Toplam Hacminiz {total}',
  AdvancedStopLess:
    'Endeks Fiyatı {stopLoss} USD\'ye ulaştığında, Zarar Durdur tetiklenecek ve tahmini K/Z {stopLossPNL} USD olacaktır."',
  AdvancedProfit:
    "Endeks Fiyatı {takeProfit} USD'ye ulaştığında, Kar Al tetiklenecek ve tahmini K7Z {tkpPNL} USD olacaktı.",
  'Your Rewards': 'Ödüllerin',
  'BLP Vault': 'BLP Kasası',
  'Total Supply': 'Toplam Arz',
  'BLP Vault balance': 'BLP Kasa Bakiyesi',
  Entry: 'Giriş',
  MinPayUSD: 'Min ödeme: {min} USD',
  MaxPayUSD: 'Maksimum emir boyutu: {max} USD',
  MAXLeverage: 'Maksimum kaldıraç: {max}x',
  MinLeverage: 'Minimum kaldıraç: {min}x',
  MAXExceeded: 'Maksimum {toSymbol} {pos} aşıldı',
  ConnectWallet: 'Cüzdan Bağla',
  Index: 'Endeks',
  Price: 'Fiyat',
  Disconnect: 'Bağlantıyı kes',
  Perpetual: 'Sürekli',
  Exchange: 'Borsa',
  Copy: 'Kopyala',
  Download: 'İndir',
  Twitter: 'Twitter',
  Share: 'Paylaş',
  Winrate: 'Kazanma Oranı',
  Average: 'Ortalama',
  profit: 'kar',
  loss: 'zarar',
  Postion: 'Pozisyon',
  Net: 'Net',
  Value: 'Değer',
  Close: 'Kapat',
  Collateral: 'Teminat',
  Edit: 'Düzenle',
  Limit: 'Limit',
  Cancel: 'İptal et',
  Fund: 'Fon',
  Fee: 'İşlem Ücreti',
  Submit: 'Gönder',
  fundFeeInfoP1: 'Fonlama oranı, long pozisyonlar ve short pozisyonlar arasındaki oranı dengelemek için kullanılır.',
  TermsAndConditions: 'BLEX Şartlar ve Koşullar',
  MediaKit: 'Medya Kiti',
  Approve: 'Onayla',
  'Connect wallet': 'Cüzdan Bağla',
  'Hide Other Pairs': 'Diğer Çiftleri Gizle',
  Referrals: 'Referanslar',
  ReferralsSubTitle: 'BLEX referans programı aracılığıyla işlem ücreti indirimleri, ödüller ve iadeler alın.',
  CompetitionsSubtitle: "Yarışmalar, en yüksek toplam kara ve işlem ROI'sına sahip olan kullanıcıları belirleyecektir.",
  Governance: 'Governance',
  Community: 'Komünite',
  Developers: 'Geliştiriciler',
  Docs: 'Dokümantasyon',
  'Contact us': 'Bize ulaş',
  'Who We Are?': 'Biz Kimiz?',
  'Terms of Service': 'Hizmet Şartları',
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    'BTC, ETH ve diğer önde gelen kripto paralarla 100x kaldıraça kadar işlem yapın.',
  'For trader': 'Kullanıcılar için',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    'Kendi cüzdanınızı kullanarak 100x kaldıraça kadar, slipaj olmaksızın geniş ticaret derinliği ile sürekli vadeli işlem yapın!',
  Trade: 'Trade',
  'For vault LP': 'LP kasası için',
  'Earn trading fee with in stable coin staking. No impermanent loss.':
    'Stabilcoin stake ederek işlem ücretlerini kazanın, geçici kayıplardan kurtulun.',
  Earn: 'Kazan',
  'Asset Safety': 'Varlık Güvenliği',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'BLEX, sürekli vadeli işlemler hizmeti veren on-chain merkeziyetsiz bir borsadır ve güvenli fonlar ile şeffaf veri akışını garanti eder.',
  'Avoid IL': 'Geçici kayıpları önle',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    'Sürekli vadeli işlem mekanizmasını sayesinde USD LP havuzu geçici kayıpları önler.',
  'Value Capture': 'Değer Yakalama',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    "İşlem ücretlerinin %70'ini kazanmak için stake ederek protokolün değerini yakalayın.",
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    "Hem WEB'de hem de mobil uygulama kullanarak ticaret yapılabilir. Şu anda kullandığınız cüzdan ile bağlanabilirsiniz. Katılmak için sadece bir adım uzaklıkta!",
  learnMore: 'Daha Fazla Bilgi Edin',
  'referrals.question': 'BLEX Referans programı nasıl çalışıyor?',
  'referrals.answer1': 'İndirimler ve iadeler her Çarşamba USDT olarak dağıtılacaktır.',
  'referrals.answer2':
    "Referans programı, kullanıcılara %10'a kadar işlem ücreti indirimi ve referansa %15 geri ödeme sunar.",
  'referrals.table.date': 'Tarih',
  'referrals.table.amount': 'Miktar',
  'referrals.table.transaction': 'İşlem',
  'referrals.table.title': 'Ödül Dağıtım Geçmişi',
  'referrals.table.no_history': 'Henüz ödül dağıtım geçmişi yok.',
  'referrals.looksLike':
    'Şuanda paylaşabileceğiniz bir referans kodunuz yok gibi görünüyor. Bir kod girin ve onu zincir üstünde oluşturmak için gönder butonuna basın.',
  'referrals.referralCode': 'Referans kodu',
  'referrals.tradedVolume': 'Toplam Hacim',
  'referrals.tradersReferred': 'Referans olunan Kullanıcılar',
  'referrals.totalRebates': 'Toplam İadeler',
  referrals: 'Referans Kodları',
  'referrals.noCodes': 'Henüz referans kodu yok.',
  'referrals.ttwitterText':
    'ETH/USD ve diğer önde gelen kripto paraları cüzdanınızla 100x kaldıraça kadar doğrudan işlem yapın. @blex.io',
  'referrals.codeLenTip': 'Referans kodu 20 karakterden fazla olamaz',
  'referrals.title': 'Referans kodu gir',
  'referrals.subTitle': 'İşlem ücreti indirimlerinden faydalanmak için bir referans kodu girin',
  'referrals.btnText': 'Gönder',
  'referrals.editBtnText': 'Değişiklikleri Kaydet',
  'referrals.successMsg': 'Referans kodu güncellendi!',
  'CancelReason.Padding': 'Tanımsız',
  'CancelReason.Liquidation': 'Likitasyon gerçekleşti ve otomatik iptal edildi',
  'CancelReason.PositionClosed': 'Pozisyon kapatıldı ve otomatik iptal edildi',
  'CancelReason.TpAndSlExecuted': 'KA & ZD gerçekleşti ve otomatik iptal edildi',
  'CancelReason.SysCancel': 'Sözleşme yürütme sırasında bir hata oluştu',
  'CancelReason.PartialLiquidation': 'Kısmi likitasyon ve otomatik iptal edildi',
  above: 'üstünde',
  below: 'altında',
  long: 'long',
  short: 'short',
  tp: 'kar al',
  sl: 'zarar durdur',
  and: 've',
  OrderType: 'Emir Türü',
  'Orders.noPos': 'Açık pozisyon yok, pozisyon açılmadıkça emir gerçekleştirilemez.',
  'Orders.orderSize': 'Emir büyüklüğü pozisyonundan daha büyük, sadece pozisyon olduğunda gerçekleştirilebilir.',
  'Orders.limitReached': 'Tetik emri üst sınıra ulaştı ve zarar durdur / kar al emirleri oluşturulamayabilir.',
  'Orders.stopLoss': 'Zarar durdur {triggerAbove} Likidasyon Fiyatı ve zarar durdur emri oluşturulamayabilir.',
  'Orders.orderWithType': 'Bu emir bir {type} emridir.',
  'Orders.orderSet': 'Bu emir bir {TP} {and} {SL} emri belirledi.',
  'Orders.pnlText':
    "Endeks fiyatı {triggerPrice} USD'ye ulaştığında {type} tetiklenecek ve tahmini PNL {PNL} USD olacaktır.",
  'Orders.tpOrSL': 'Kar Al / Zarar Durdur',
  'Orders.editOrderTitle': 'Emir {type} Düzenle',
  Positions: 'Pozisyonlar',
  History: 'Geçmiş',
  _Orders: 'Emirler',
  orderPrice: 'Emir Fiyatı',
  indexPrice: 'Endeks Fiyatı',
  tpSL: 'KA / ZD',
  cancelAll: 'Tümünü iptal et',
  WARNING: 'UYARI',
  liqPrice: 'Lik. Fiyatı',
  enterPrice: 'Bir Fiyat Gir',
  priceAboveCurrent: 'Şimdiki fiyatın üstündeki fiyat',
  stopLossAboveOrder: 'Emir fiyatının üstünde zararı durdur',
  stopLossBelowLiq: 'Lik. fiyatının altında zararı durdur',
  takeProfitBelowOrder: 'Emir fiyatının altında kar al',
  priceBelowCurrent: 'Şimdiki fiyatın altındaki fiyat',
  stopLossBelowOrder: 'Emir fiyatının altında zararı durdur',
  stopLossAboveLiq: 'Lik. fiyatının üstünde zararı durdur',
  takeProfitAboveOrder: 'Lik. fiyatının altında kar al',
  priceBelowLiq: 'Lik. fiyatının altındaki fiyat',
  priceAboveLiq: 'Lik. fiyatının üstündeki fiyat',
  updatingOrder: 'Emir güncelleştiriliyor…',
  updateOrder: 'Emri Güncelleştir',
  Pair: 'Çift',
  'position.noOpenPositions': 'Açık Pozisyon Yok',
  'position.sharePosition': 'Pozisyonu Paylaş',
  'position.warinitePositions':
    'Bu pozisyon, borç alma işlem ücretleri de düşüldükten sonra düşük bir teminat miktarına sahip, pozisyonun likidasyon riskini azaltmak için daha fazla teminat yatırın.',
  Opening: 'Açılıyor...',
  Closing: 'Kapatılıyor...',
  editCollateral: 'Teminatı Düzenle',
  entryPrice: 'Giriş Fiyatı',
  netValue: 'Net Değer',
  Market: 'Piyasa',
  Trigger: 'Tetiklenme',
  welcomeTo: "{type}'e Hoş Geldiniz.",
  trustedByUser: "{uniqueUsersTotal}'den fazla kullanıcı tarafından güvenilen bir platform.",
  addedLastWeek: 'Geçen hafta işlem hacmi {money}',
  tierAndRebate: 'Seviye {level} (%{amount} iade)',
  tierAndDiscount: 'Seviye {level} (%{amount} indirim)',
  closeIsLongSymbol: '{isLong} {symbol} kapat',
  'Stake USDT and earn rewards from fees generated on the BLEX':
    "USDT stake edin ve BLEX'te oluşan işlem ücretlerinden ödüller kazanın.",
  'The BLP Vault is the counter party to all trades on BLEX':
    "BLP Kasası, BLEX'teki tüm işlemlerin karşıt pozisyonunda yer alır",
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    "BLEX Vault, platform tarafından üretilen ücretlerin %70'ini biriktirir. BLEX Vakfı ise platform tarafından üretilen ücretlerin %30'unu biriktirir.",
  'Hello!': 'Merhaba!',
  '24h Trades': '24 Saatlik Ticaretler',
  '24h Volume': '24 Saatlik Hacim',
  'No data': 'Veri yok',
  'Up to 20%': "%20'ye kadar",
  'Stake USDT and earn rewadrs from fees generated on the BLEX':
    "USDT stake edin ve BLEX'te oluşan işlem ücretlerinden ödüller kazanın.",
  'Lowest transaction fees': 'En düşük işlem ücretleri',
  'Referral code added!': 'Referans kodu eklendi!',
  'Create code failed': 'Kod oluşturulamadı',
  'Generate Referral Code': 'Referans Kodu Oluştur',
  'No rewards distribution history yet': 'Henüz ödül dağıtım geçmişi yok.',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    "BLEX'te USD gibi stabil kripto paralar ile işlem yaparken, görüntülenen açık pozisyon miktarları gerçek coin miktarına dayalı olarak hesaplanır. Değerler son fiyata göre değişir.",
  'You have an active trigger order that could impact this Position':
    'Bu pozisyonu etkileyebilecek birden fazla aktif tetikleme emriniz var.',
  'You have multiple active trigger orders that could impact this Position':
    'Bu pozisyonu etkileyebilecek aktif bir tetikleme emriniz var',
  'You have an active Limit Order that could impact this Position':
    'Bu pozisyonu etkileyebilecek aktif bir limit emriniz var',
  'Creating Order': 'Emir Oluşturma',
  Longing: 'Longing',
  Shorting: 'Shorting',
  'Position Size': 'Pozisyon Boyutu',
  Leverage: 'Kaldıraç',
  Side: 'Yön',
  Increase: 'Arttır',
  Long: 'Long',
  Short: 'Short',
  'Price below liq.price': 'Lik. fiyatının altındaki fiyat',
  'Price above liq.price': 'Lik. fiyatının üstündeki fiyat',
  'Max close amount exceeded': 'Maksimum kapatma miktarı aşıldı',
  'Creating order': 'Emir oluşturma',
  'Create order': 'Emir Oluştur',
  'Keep Leverage': 'Kaldıraçı Koru',
  Receive: 'Al',
  Deposit: 'Yatır',
  Withdraw: 'Çek',
  'Invalid liq.price': 'Geçersiz Lik. Fiyatı',
  Withdrawing: 'Çekiliyor',
  Depositing: 'Yatırılıyor',
  Pay: 'Öde',
  'Bug Bounty': 'Bug Bounty',
  'Submit a Bug': 'Bug bildir',
  Balance: 'Bakiye',
  'Collateral: Initial Collateral - Funding Fee': 'Teminat: İlk Teminatı - İşlem Ücreti',
  'I have made trades on BLEX, come with me!': 'Ben ble',
  '+$0.00': '#ERROR!',
  ReferralTerms: 'Referans Şartları',
  'Confirm Limit Order': 'Limit Emrini Onayla',
  'Create Order': 'Emir Oluştur',
  Prev: 'Önceki',
  'Share Time': 'Paylaşım Süresi',
  Next: 'Sonraki',
  'Next Funding Fee': 'Sonraki Fonlama Ücreti',
  Countdown: 'Geri Sayım',
  'Waiting for update': 'Güncelleme Bekleniyor',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    'Long ve short pozisyonlar arasında bir sonraki fonlama için değişen ödeme oranıdır. Fonlama oranı pozitifse fonlama ücreti ödenir. Negatifse fonlama ücreti alınır.',
  'Your network is not good, please reconnect your network': 'Ağınız iyi değil, lütfen ağınızı yeniden bağlayın',
  'Total Volume': 'Toplam Hacim',
  'Top{no}': 'Top{no}',
  'Share {no}': '{no} Ödül Paylaşımlı',
  'Futures Trading Competition': 'Futures Trading Yarışması',
  'Current total trading volume (USDT)': 'Mevcut toplam işlem hacmi (USDT)',
  'Ends in': 'İçinde biten',
  'Withdraw collateral': 'Teminat Çekme',
  'Deposit collateral': 'Teminat Yatırma',
  'Open Orders': 'Açık Siparişler',
  'Order History': 'Sipariş Geçmişi',
  Bar: 'Çubuk Grafikler',
  Candle: 'Mum Grafikler',
  Line: 'Çizgi',
  Area: 'Alan',
  'Hollow Candle': 'İçi Boş Mumlar',
  Baseline: 'Temel Çizgi',
  'Hi-Lo': 'Yüksek-düşük',
  O: 'A',
  H: 'Y',
  L: 'D',
  C: 'K',
  'Event Rules >': 'Etkinlik Kuralları >',
  'Competitions will reward traders based on the volume target and rank.':
    'Yarışmalarda işlem hacmi hedeflerine ve sıralamaya göre kullanıcılara ödül verilecektir.',
  'Trade Now': 'Ticaret',
  'Current Total Volume': 'Mevcut Toplam Hacim',
  'TP/SL Keep Leverage': 'TP/SL Kaldıraçı Koru',
  Leaderboard: 'Liderlik Tablosu',
  ROI: 'ROI',
  Million: 'Milyon',
  PositionStatusExecuted: 'Gerçekleştirildi: Bu emrin başarıyla tamamlandığını gösterir.',
  PositionStatusRequest:
    'Talep Edildi: Emir talebinin başarılı olduğunu gösterir, ancak emir gerçekleştirilirken lütfen sabırlı olun.',
  PositionStatusCancelled: 'İptal Edildi: Bu siparişin iptal edildiğini gösterir.',
  '15-minute waiting period for selling purchased BLP.':
    'Satın alınan BLP nin satışı için 15 dakikalık bekleme süresi.',
  'System is currently upgrading...': 'Sistem şu anda güncelleniyor...',
  'Copy Link': 'Bağlantıyı Kopyala',
  'Referral Code copied to your clipboard': 'Referans Kodu panonuza kopyalandı',
  'Total Discount': 'Toplam İndirim',
  'Total Rebate': 'Toplam İade',
  'Total Discounts Earned by this Account as a Trader.': 'Bu Hesabın Bir Tüccar Olarak Kazandığı Toplam İndirim.',
  'Total Trading Volume with Valid Referral Codes for this Account.':
    'Bu Hesap İçin Geçerli Tavsiye Kodları ile Yapılan Toplam İşlem Hacmi.',
  'Total Rebates Earned by this Account as a Referrer.': 'Bu Hesabın Bir Tavsiye Eden Olarak Kazandığı Toplam İade.',
  'Total Trading Volume of Recommended Traders for this Account.':
    'Bu Hesap İçin Önerilen Tüccarların Toplam İşlem Hacmi.',
  'Total Number of Traders Recommended by this Account.':
    'Bu Hesap Tarafından Tavsiye Edilen Tüccarların Toplam Sayısı.',
  Doc: 'Belgeler',
  Audit: 'Denetim',
  'Transaction Records': 'İşlem Kayıtları',
  'Reward Records': 'Ödül Kayıtları',
  'Total Amount': 'Toplam Tutar',
  'Total Buy Amount': 'Toplam Alım Miktarı = Fiyat * Miktar + İşlem Ücretleri',
  'Total Sell Amount': 'Toplam Satış Miktarı = Fiyat * Miktar - İşlem Ücretleri',
  'Transaction was cancelled.': 'İzin verme talebiniz reddedildi. Lütfen başvuruyu kontrol edin.',
  'Market Order Beyond the slippage price and canceled.': 'Piyasa fiyatı, slippage fiyatını aşıyor。',
  handCancel: 'İzin verme talebinizi reddettiniz. Lütfen başvuruyu kontrol edin.',
  'System Cancel': 'Sistem İptali',
  'Error encountered during contract execution(1000).': 'Sistem hatası',
  Skip: 'Atla',
  'Phase II': 'II. Aşama',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    'Sizin için kilidini açabileceğiniz özel avantajlar ve ödüller, yeni kullanıcılara özel ödüller!',
  'Will Start In': 'Başlangıç tarihi',
  'So Stay Tuned!': 'Takipte Kalın!',
  'Invite Contest': 'Davet Yarışması',
  'Invite to earn $2,300': '$2,300 kazanmak için davet edin',
  'Trading Volume Contest': 'İşlem Hacmi Yarışması',
  'Trade Futures Volume earn $46,500': 'Futures İşlem Hacmi ile $46,500 kazanın',
  'Trading ROI Contest': 'ROI Yarışması',
  'Trade Futures ROI to earn $1,200': 'Futures ROI ile $1,200 kazanın',
  'Trading Contest': 'İşlem Yarışması',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    'Etkinlik süresince, blex borsasında toplam işlem hacmine dayalı olarak ilk 50 kullanıcın $46,500 USDT ödül havuzunu paylaşacaklar ve gelecekteki işlem hacimlerine dayanacaklar.',
  'Detail Rules': 'Detaylı Kurallar',
  'When Total Trading Volume Reaches': 'Toplam İşlem Hacmi Ulaşıldığında',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    'Etkinlik süresince, arkadaşlarınızı davet ederek davet kodu/bağlantınız aracılığıyla işlem yapmaya davet edin. Hem davet eden kullanıcı hem de davet edilen kullanıcı {value} alacak. Ödül, ilk 500 geçerli davete sınırlıdır, ilk gelen ilk hizmet alır.',
  'Note: The maximum reward for an individual user is $20 USDT.':
    "Not: Bireysel bir kullanıcı için maksimum ödül {value} 'dir.",
  'Invite Now': 'Şimdi Davet Edin',
  'ROI Rank': 'ROI Sıralaması',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    'Etkinlik sırasında, {value}, kullanıcıları gelecekteki işlem karlılıklarına (ROI) göre ödüllendirecek. {value1} hem de ilk {value2} arasındakiler bu oturumda her biri {value3} alacak.',
  Notices: 'Duyurular',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    '1.Kullanıcılar ödüllere hak kazanmak için etkinlik gereksinimleri karşılamalıdır.',
  '2.The rewards will be issued within 7 working days after the event.':
    '2.Ödüller, etkinlikten sonraki {value}  iş günü içinde dağıtılacaktır.',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '3.İşlem hacmi yalnızca Futures işlemleri içerir. {value}',
  '4.Users need to accumulate at least 5U to win.': '4.Kullanıcılar kazanmak için en az  {value} biriktirmelidir.',
  '5.No spam is allowed, or you will be disqualified.':
    '5.Spam yapmak yasaktır, aksi takdirde diskalifiye edileceksiniz.',
  '6.BLEX reserves the right to the final interpretation of this event.':
    '6.BLEX, bu etkinliğin nihai yorumlama hakkını saklı tutar.',
  '7.If you have any questions, please contact our admin via':
    '7.Herhangi bir sorunuz varsa, lütfen bizimle iletişime geçin：',
  'Your ROI': "ROI'niz",
  'ROI = (Profit + UnProfit) / Cum.Collateral': 'ROI = (Kâr + Zarar) / Kumulatif Teminat',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral':
    'Kumulatif Teminat = Toplam Açık Pozisyon + Toplam Teminat Yatırma',
  'Volume = Total Size (Open Size + Close Size)': 'Hacim = Toplam Boyut (Açık Boyut + Kapanış Boyutu)',
  'Trading Volume = Opened Positions + Closed Positions.': 'İşlem Hacmi = Açık Pozisyonlar + Kapanan Pozisyonlar.',
  'discord or telegram': 'discord veya telegram.',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.':
    'Ayrıca, etkinlik sona erdikten sonra en {value} davet eden kullanıcı ekstra {value1} ödül alabilir.',
  '3.The trading volume only includes Futures trading.': '3.İşlem hacmi yalnızca Futures işlemleri içerir.',
  Top3: 'Top3',
  'Bottom 3': 'En Alt 3',
  'no list data': 'Henüz liste bilgisi yok',
  'mail notice': 'E-posta Bildirimi',
  'mail info Add':
    'Cüzdan merkezi "E-posta Bildirimi" özelliği ekledi, etkinleştirildiğinde pozisyonla ilgili bilgileri zamanında almanızı sağlar.',
  'open mail subscrip': 'Burada aynı şekilde "E-posta Aboneliği" özelliğini de etkinleştirebilirsiniz.',
  'got it': 'Anladım.',
  'provide mail account':
    'Lütfen sık kullandığınız e-posta adresinizi sağlayın, size her zaman pozisyonla ilgili uyarı bildirimleri göndereceğiz.',
  'please enter email account': 'Lütfen e-posta adresinizi girin.',
  'error style re enter': 'E-posta formatı yanlış, lütfen yeniden girin.',
  'confirm open': 'Açmayı onayla',
  'close mail notice': 'E-posta bildirimlerini kapat',
  'close mail info': 'E-posta b',
  'comfir close': 'Kapatmayı onayla',
  considerate: 'Düşünün',
  'open info': 'E-posta bildirimlerini açarak pozisyon bilgilerinizi hızlıca alın',
  'please enter mail': 'Lütfen e-posta adresinizi girin',
  'unvaild mail': 'E-posta formatı yanlış, lütfen yeniden girin',
  'open mail success':
    'E-posta bildirimleri başarıyla etkinleştirildi. Lütfen istediğiniz zaman e-postanızı kontrol edin',
  'close mail success':
    'E-posta bildirimleri başarıyla iptal edildi. Platform artık size pozisyonla ilgili uyarı bildirimleri göndermeyecek',
  next: 'Sonraki adım',
  'Internal JSON-RPC error.': 'Dahili JSON-RPC hatası.',
  'Market Order Beyond the slippage price and canceled': 'Piyasa emri slippage fiyatını aştı ve iptal edildi.',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    'Endeks fiyatı sadece referans içindir ve gerçek işlem fiyatı geçerlidir.',
  'PNL after fees':
    'Ücretlerden Sonra PNL = PNL - Pozisyon Ücreti - Finansman Ücreti - İşlem Ücreti - Likidasyon Ücreti',
  'Historical Peak': 'Tarihi Zirve',
  'Super Referrers': 'Tüccarlar Yardım',
  'Contact us for more discounts': 'Daha fazla indirim için bize ulaşın',
  'PNL / ROI': 'K/Z / ROI',
  'Target Price': 'Hedef Fiyat',
  'Calculations are for reference only.': 'Hesaplamalar sadece referans içindir.',
  Calculator: 'Hesap makinesi',
  Calculate: 'Hesapla',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.':
    'Buradaki başlangıç teminatı hesaplama işlemi, düşülmüş olan herhangi bir ücret veya açılış kaybını içermez.',
  'PNL After Fees': 'İşlem Ücretleri sonrası K/Z',
  'Unable to reach this return rate.': 'Bu getiri oranına ulaşılamıyor.',
}
